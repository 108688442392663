<template>
  <v-footer class="pa-0">
    <v-card elevation="0" rounded="0" width="100%" class="bg-grey text-center pb-3">
      <v-card-text class="pb-0">
        <v-btn 
          class="mx-4 icon-bot-pad" 
          icon="fas fa-house" 
          variant="plain" 
          color="white" 
          href="https://www.houzz.com/professionals/photographers/architectural-photography-by-phil-coman-pfvwus-pf~755879709" 
          target="_blank"
        />
        <!-- <v-btn class="mx-4 icon-bot-pad" icon="fa-brands fa-pinterest" variant="plain" color="white"/> -->
        <!-- <v-btn class="mx-4 icon-bot-pad" icon="fas fa-message" variant="plain" color="white"/> -->
        <!-- <v-btn class="mx-4 icon-bot-pad" icon="fab fa-facebook" variant="plain" color="white"/> -->
        <!-- <v-btn class="mx-4 icon-bot-pad" icon="fa-brands fa-instagram" variant="plain" color="white"/> -->
      </v-card-text>
      <v-card-text class="text-white" id="footer-bottom">
        <span class="logo-line font-weight-bold">
          Philip Coman Photography &copy; - {{ new Date().getFullYear() }}
        </span>
        <hr width="150px" class="mx-auto my-2" color="#e0e0e0">
        Website by <a class="text-decoration-none font-weight-medium text-white" href="https://brandontech.ca" target="_blank">Brandon Winger-Air</a> <strong>|</strong> All Rights Reserved
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
  export default {
    
  }
</script>

<style scoped>
.icon-bot-pad {
  padding-bottom: 2px;
}

.logo-line {
  font-size: 16px;
}

.copyright-sign {
  position: relative;
  font-size: 10px;
  top: -5px;
}

hr {
  border-bottom: 0;
}
</style>